import { Component, Input } from '@angular/core';
import { UploadFile } from 'src/app/core/models/models';
import { AltImagePipe } from 'src/app/shared/pipes/alt-image.pipe';
import { PathImagePipe } from 'src/app/shared/pipes/path-image.pipe';

@Component({
  selector: 'app-picture-item',
  standalone: true,
  imports: [PathImagePipe, AltImagePipe],
  templateUrl: './picture-item.component.html',
  styleUrl: './picture-item.component.scss'
})
export class PictureItemComponent {
  @Input() image!: UploadFile | undefined;
  @Input() view: 'admin' | 'original' | '' = '';
  @Input() classes!: string;
}
